import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '~public/frontend/components/Loader';
import { gql, useQuery } from '@apollo/client';
import { useConfig } from '~components/hooks';
import '~public/frontend/scss/pages/_profile-landing.scss';

import ProfileLandingBenefits1 from '~public/frontend/scss/images/profile-landing-benefits-1.svg';
import ProfileLandingBenefits2 from '~public/frontend/scss/images/profile-landing-benefits-2.svg';
import ProfileLandingBenefits3 from '~public/frontend/scss/images/profile-landing-benefits-3.svg';
import ProfileLandingBenefits4 from '~public/frontend/scss/images/profile-landing-benefits-4.svg';
import ProfileLandingBenefits5 from '~public/frontend/scss/images/profile-landing-benefits-5.svg';
import ProfileLandingBenefits6 from '~public/frontend/scss/images/profile-landing-benefits-6.svg';

import { AuthBlock } from '~public/frontend/components/AuthBlock';

const Home: FunctionComponent = () => {
  const getConfig = useConfig();
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(gql`
    query Home {
      geferralStats {
        partnerCount
        promoCodeCount
        promoCodeActivate
      }
    }
  `);

  if (loading) {
    return <Loader />;
  } else if (error) {
    return <>Referral | Error! {error.message}</>;
  }

  const { geferralStats } = data;

  return (
    <div className="profile-landing">
      <div className="open-cases">
        <section>
          <h1>{t('Earn up to % from deposits', { percent: 20 })}</h1>
          <h3>{t('WITHDRAW FUNDS WHERE CONVENIENT')}</h3>
          <div className="open-cases-btns">
            <a className="advantages-sign-in-steam" href="/auth/steam">
              <div className="small-text">{t('Sign in through')}</div>
              <div className="text">Steam</div>
              <div className="top"></div>
              <div className="bot-1"></div>
              <div className="bot-2"></div>
            </a>
            {getConfig.hostname !== 'cscase.com' && (
              <a
                className="advantages-sign-in-vk"
                href="/auth/vk"
                data-reachgoal="vk_auth"
              >
                <div className="top"></div>
                <div className="bot-1"></div>
              </a>
            )}

            <a
              className="advantages-sign-in-fb"
              href="/auth/fb"
              data-reachgoal="fb_auth"
            >
              <div className="top"></div>
              <div className="bot-1"></div>
            </a>
            {getConfig.hostname === 'cscase.com' && (
              <a
                className="advantages-sign-in-google"
                href="/auth/google"
                data-reachgoal="g_auth"
              >
                <div className="top"></div>
                <div className="bot"></div>
              </a>
            )}
          </div>
          <AuthBlock />
        </section>
      </div>
      <div className="about-project-wrap">
        <div className="about-project">
          <h2>{t('How it works?')}</h2>
          <div className="about-project-content">
            <p>{t('ABOUT_PROJECT_CONTENT_1')}</p>
            <p>{t('ABOUT_PROJECT_CONTENT_2')}</p>
            <p>{t('ABOUT_PROJECT_CONTENT_3')}</p>
          </div>
        </div>
        <div className="about-benefits">
          <h2>{t('ABOUT_PROJECT_CONTENT_4')}</h2>
          <div className="about-benefits-items">
            <div className="about-benefits-item">
              <div className="about-benefits-item-icon">
                <img src={ProfileLandingBenefits1} />
              </div>
              <div className="about-benefits-item-title">
                {t('ABOUT_PROJECT_CONTENT_5')}
              </div>
              <div className="about-benefits-item-text">
                {t('ABOUT_PROJECT_CONTENT_6')}
              </div>
            </div>
            <div className="about-benefits-item">
              <div className="about-benefits-item-icon">
                <img src={ProfileLandingBenefits2} />
              </div>
              <div className="about-benefits-item-title">
                {t('ABOUT_PROJECT_CONTENT_7')}
              </div>
              <div className="about-benefits-item-text">
                {t('ABOUT_PROJECT_CONTENT_8')}
              </div>
            </div>
            <div className="about-benefits-item">
              <div className="about-benefits-item-icon">
                <img src={ProfileLandingBenefits3} />
              </div>
              <div className="about-benefits-item-title">
                {t('ABOUT_PROJECT_CONTENT_9')}
              </div>
              <div className="about-benefits-item-text">
                {t('ABOUT_PROJECT_CONTENT_10')}
              </div>
            </div>
          </div>
        </div>
        <div className="hr-benefits"></div>
        <div className="about-benefits">
          <h2>{t('In numbers')}</h2>
          <div className="about-benefits-items">
            <div className="about-benefits-item">
              <div className="about-benefits-item-icon">
                <img src={ProfileLandingBenefits4} />
              </div>
              <div className="about-benefits-item-num">
                {geferralStats.partnerCount}
              </div>
              <div className="about-benefits-item-text">
                {t('Partners are already with us')}
              </div>
            </div>
            <div className="about-benefits-item">
              <div className="about-benefits-item-icon">
                <img src={ProfileLandingBenefits5} />
              </div>
              <div className="about-benefits-item-num">
                {geferralStats.promoCodeCount}
              </div>
              <div className="about-benefits-item-text">
                {t('Issued promo codes')}
              </div>
            </div>
            <div className="about-benefits-item">
              <div className="about-benefits-item-icon">
                <img src={ProfileLandingBenefits6} />
              </div>
              <div className="about-benefits-item-num">
                {geferralStats.promoCodeActivate}
              </div>
              <div className="about-benefits-item-text">
                {t('Promo codes already activated')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
